import React, { Component } from "react";

// react-router-dom
import { Link } from "react-router-dom";

// mdreact
import { MDBDataTable } from "mdbreact";

import { toast } from "react-toastify";
// axios
import axios from "axios";
import GetUserToken from "../component/GetUserToken";
import { baseUrl } from "../assets/baseUrl";
import { baseUrlTest } from "../assets/baseUrl";
import jwt_decode from "jwt-decode";
import { Pagination, Stack } from "@mui/material";
const token = GetUserToken();

class FilesOfSubject extends Component {
    state = {
        data: [],
        loadintable: true,
        allMonth: [
            "يناير",
            "فبراير",
            "مارس",
            "ابريل",
            "مايو",
            "يونيو",
            "يوليو",
            "اغسطس",
            "سبتمبر",
            "اكتوبر",
            "نوفمبر",
            "ديسمبر",
        ],
        fileeData: null,
        size: 10,
        index: 0,
        count: 0,
        name: ""
    };

    componentDidMount() {
        if (sessionStorage.getItem("token") != null || sessionStorage.getItem("token") != undefined) {
            const user = jwt_decode(sessionStorage.getItem("token"));

            const userRole = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
            const userEmail = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";
            const userName = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
            const userIdentifier = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
            const userRoleRes = user[`${userRole}`];
            const userEmailRes = user[`${userEmail}`];
            const userNameRes = user[`${userName}`];
            const userIDRes = user[`${userIdentifier}`];

            if (
                userRoleRes === "Admin"
            ) {
            } else {
                sessionStorage.clear();
                this.props.history.push("/");
                window.location.reload();
            }
        }
        document.querySelector('[aria-label="Previous"]').innerHTML = "السابق";
        document.querySelector('[aria-label="Next"]').innerHTML = "التالي";
        document.querySelector(".dataTables_length").classList.add('remove-pagination');
        document.querySelector(".mdb-datatable-filter input").placeholder = "بحث";
        // const PASE_URL = "http://hossam1234-001-site1.ftempurl.com/api/";
        const PASE_URL = "https://localhost:44334/api/";

        axios
            .get(`${baseUrlTest}api/FileMangment/GetAll`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.status == 200) {
                    this.setState({
                        loadintable: false,
                        count: res.data.pages,
                    });
                }

                let x = document.getElementsByClassName("pagination")[0].remove();

                var newData = res.data.items.reverse();
                newData.map((dat, index) => {
                    dat.option = (
                        <div className="option-parent">
                            <button
                                onClick={() => this.deleteFile(dat)}
                                color="red"
                                className="tableOption op-delete"
                                size="sm"
                            >
                                <i className="fi-rr-trash"></i>
                            </button>

                            {/* <Link to={"/edit-file-subject/" + dat.id} className="tableOption op-edit">
                                <i className="fi-rr-edit"></i>
                            </Link> */}
                        </div>
                    );
                    dat.index = index + 1;
                    if (dat.subject != null) {
                        dat.subjectName = dat.subject.name;
                    }
                    dat.name = (
                        <span>
                            <a
                                className="LINKSS"
                                id={"filelink" + index}
                                onClick={(e) => {
                                    axios
                                        .get(`${baseUrlTest}api/FileMangment/show?fileID=${dat.id}`, {
                                            headers: { Authorization: `Bearer ${token}` },
                                        })

                                        .then((res) => {
                                            console.log(res);
                                            this.setState({ fileeData: res });
                                            console.log(res);
                                            var x = "filelink" + index;
                                            document.getElementById(x).setAttribute("download", res.data.name);
                                            document.getElementById(x).setAttribute("href", res.data.fileData);
                                        })
                                        .catch((errors) => {
                                            console.log(errors);
                                        });
                                }}
                            >
                                {dat.name}
                            </a>
                        </span>
                    );
                    dat.lectureLink = (
                        <a href={dat.lectureLink} target="_blank" className="lecture-link">
                            رابط المحاضرة
                        </a>
                    );

                });

                this.setState({
                    data: newData,
                });
            });
            
            const searchBox = document.querySelector('[aria-label="Search"]');
            searchBox.value = ""; 
        }

    deleteFile = async (dat) => {
        this.setState({
            loadintable: true,
        });

        let oldData = [...this.state.data];

        let newDate = this.state.data.filter((item) => item.id !== dat.id);
        this.setState({
            data: newDate,
            loadintable: true,
        });

        try {
            await axios
                .delete(`${baseUrlTest}api/FileMangment/Delete?FileID=${dat.id}` , {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success("تم الحذف بنجاح");
                        this.setState({
                            loadintable: false,
                        });
                    } else {
                        toast.error("يوجد خطأ برجاء المحاولة مرة اخرى");
                        this.setState({
                            data: oldData,
                            loadintable: false,
                        });
                    }
                });
        } catch (ex) {
            toast.error("يوجد خطأ برجاء المحاولة مرة اخرى");
            this.setState({
                data: oldData,
                loadintable: false,
            });
        }
    };

    render() {
        const { loadintable, fileeData, count } = this.state;
        const datatable = {
            columns: [
                { label: "الترتيب", field: "index" },
                // { label: "الاسم", field: "name" },
                { label: "اسم الماده", field: "subjectName" },
                { label: "ملف المحاضرة", field: "name" },
                // { label: "الرابط", field: "lectureLink" },
                // { label: "رابط الملف", field: "fileLink" },
                // { label: "الشهر", field: "month" },
                // { label: "النوع", field: "isFree" },
                // { label: "الحاله", field: "isAppear" },
                { label: "الاختيارات", field: "option" },
            ],
            rows: this.state.data,
        };

        return (
            <div className="main-content">
                <div className="dashboard-header">
                    <h5>ملفات المواد</h5>
                    <div className="add-aNew">
                        <Link className="add-new-lnk" to="/add-new-file-subject">
                            اضافة ملف
                        </Link>
                    </div>
                </div>
                <div className="dashboard-content">
                    <div className="dasboard-box">
                        <MDBDataTable
                            entriesOptions={[5, 10, 15, 20]}
                            entries={10}
                            pagesAmount={4}
                            hover
                            large
                            responsive
                            data={datatable}
                        />
                        {loadintable ? (
                            <div className="loading-par">
                                <div className="sp sp-volume"></div>
                                <h5>برجاء الانتظار</h5>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default FilesOfSubject;
