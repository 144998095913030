// export const baseUrl = "http://hossam1234-001-site1.ftempurl.com/";
// export const baseUrl = "http://shandoo-001-site1.ctempurl.com/";
// export const baseUrl = "https://localhost:44334/";
// export const baseUrl = "https://restfulapi.golearn.site/";
// export const baseUrl = "https://api.golearn.site/";
// export const baseUrl = "http://back.mrpetersalama.com/";
export const baseUrl =
  // "https://www.exciting-swartz.162-220-162-242.plesk.page/";
  "https://dazzling-wozniak.69-10-32-26.plesk.page/";
  export const baseUrlTest = "https://dazzling-wozniak.69-10-32-26.plesk.page/";
  // export const baseUrlTest = "https://backend.drmohamedabdelrahman-anatomy.com/";

// Admin@learning.com
// Admin@123456
