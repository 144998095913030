import React, { Component } from "react";

import { Link } from "react-router-dom";

// mdreact
import { MDBDataTable } from "mdbreact";

import { toast } from "react-toastify";

import axios from "axios";
import GetUserToken from "../component/GetUserToken";
import { baseUrl } from "../assets/baseUrl";
import { baseUrlTest } from "../assets/baseUrl";
import jwt_decode from "jwt-decode";
import { Pagination, Stack } from "@mui/material";
const token = GetUserToken();

export default class ResetAccount extends Component {
  state = {
    data: [],
    loadintable: true,
    index: 0,
    size: 10,
    count: 0,
  };

  // handlePaginationChange = (event, value) => {
  //   this.setState({
  //     loadintable: true,
  //   });
  //   axios
  //     .get(
  //       `${baseUrl}api/ResetIPRequests/GetAllRequest`,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.status == 200) {
  //         this.setState({
  //           count: res.data.pages,
  //           loadintable: false,
  //         });
  //       }

  //       var newData = res.data.items.reverse();
  //       // newData.map((dat, index) => {
  //       //   dat.option = (
  //       //     <div className="option-parent">
  //       //       <button
  //       //         onClick={() => this.acceptReOpen(dat)}
  //       //         color="red"
  //       //         className="tableOption op-done"
  //       //         size="sm"
  //       //       >
  //       //         <i className="fi-rr-check"></i>
  //       //       </button>
  //       //     </div>
  //       //   );
  //       //   dat.checkBox = (
  //       //     <input
  //       //       className="checkers"
  //       //       type="checkbox"
  //       //       onChange={(e) => this.onRowCheckChanged(e, index)}
  //       //       checked={dat.isChecked}
  //       //       name={index}
  //       //       id={index}
  //       //     />
  //       //   );
  //       //   dat.index = index + 1;
  //       // });
  //       this.setState({
  //         data: newData,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       this.setState({
  //         loadintable: false,
  //       });
  //     });
  // };

  componentDidMount() {
    if (
      sessionStorage.getItem("token") != null ||
      sessionStorage.getItem("token") != undefined
    ) {
      const user = jwt_decode(sessionStorage.getItem("token"));
      const userRole =
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
      const userEmail =
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";
      const userName =
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
      const userIdentifier =
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
      const userRoleRes = user[`${userRole}`];
      const userEmailRes = user[`${userEmail}`];
      const userNameRes = user[`${userName}`];
      const userIDRes = user[`${userIdentifier}`];

      if (userRoleRes === "Admin") {
      } else {
        sessionStorage.clear();
        this.props.history.push("/");
        window.location.reload();
      }
    }
    document.querySelector('[aria-label="Previous"]').innerHTML = "السابق";
    document.querySelector('[aria-label="Next"]').innerHTML = "التالي";
    document.querySelector(
      ".dataTables_length"
    ).classList.add('remove-pagination');
    document.querySelector(".mdb-datatable-filter input").placeholder = "بحث";
    const token = GetUserToken();
    document.getElementsByClassName("pagination")[0].remove();
    this.getAllResetRequests()
  }

  getAllResetRequests(){
    const token = GetUserToken();
     axios
      .get(
        `${baseUrlTest}api/ResetIPRequests/GetAllRequest`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            count: res.data.pages,
            loadintable: false,
          });
        }
        var newData = res.data.items.reverse();
        newData.map((dat, index) => {
          dat.option = (
            <div className="option-parent">
              <button
                onClick={() => this.acceptReOpen(dat, true)}
                color="red"
                className="tableOption op-done"
                size="sm"
              >
                <i className="fi-rr-check"></i>
              </button>
              <button
                onClick={() => this.acceptReOpen(dat, false)}
                color="red"
                className="tableOption op-delete"
                size="sm"
              >
                <i className="fi-rr-trash"></i>
              </button>
            </div>
          );
          dat.checkBox = (
            <input
              className="checkers"
              type="checkbox"
              onChange={(e) => this.onRowCheckChanged(e, index)}
              checked={dat.isChecked}
              name={index}
              id={index}
            />
          );
          dat.index = index + 1;
        });
        this.setState({
          data: newData,
        });

        })
         .catch((error) => {
            console.log(error);
            this.setState({
                loadintable: false,
            });
        });
  }

  acceptReOpen = (dat, value) => {
    this.setState({
      loadintable: true,
    });

    const reobj = {
      requestId: dat.requestId,
      isApproved: value,
    };
    axios
      .post(`${baseUrlTest}api/ResetIPRequests/AcceptRequest `, reobj, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success("تم اعادة فتح المحاضرة");
          this.setState({ loadintable: false });
          this.getAllResetRequests()
        }
      })
      .catch((error) => {
        toast.error("يوجد مشكلة ما");
      });

    let newDate = this.state.data.filter((item) => item.id != dat.id);
    this.setState({
      data: newDate,
      loadintable: true,
    });
  };
  onRowCheckChanged = (e, index) => {
    const { id, checked } = e.target;
    console.log(e.target);
    if (id === "selectAll") {
      if (this.state.filteredData != null && this.state.filteredData != []) {
        let tempSubscribers = this.state.filteredData.map((subscriber) => {
          var x = subscriber;
          x["isChecked"] = checked;
          return x;
        });

        this.setState({ filteredData: tempSubscribers });
        var xx = document.getElementsByClassName("checkers");
        for (let i = 0; i < xx.length; i++) {
          xx[i].checked = checked;
        }
      } else {
        let tempSubscribers = this.state.data.map((subscriber) => {
          var x = subscriber;
          x["isChecked"] = checked;
          return x;
        });
        this.setState({ newData: tempSubscribers });

        var xx = document.getElementsByClassName("checkers");
        for (let i = 0; i < xx.length; i++) {
          xx[i].checked = checked;
        }
      }
    } else {
      if (this.state.filteredData != null && this.state.filteredData != []) {
        let tempSubscribers = this.state.filteredData.map(
          (subscriber, index2) => {
            if (index2 === index) {
              var x = subscriber;
              x["isChecked"] = checked;
              return x;
            } else {
              var x = subscriber;
              if (subscriber.isChecked != null) {
                x["isChecked"] = subscriber.isChecked;
              } else {
                x["isChecked"] = false;
              }
              return x;
            }
          }
        );
        this.setState({ filteredData: tempSubscribers });

        let truesAndFalses = [];
        this.state.filteredData.map((subscriber, index) => {
          truesAndFalses.push(subscriber.isChecked);
          if (truesAndFalses.includes(true) && truesAndFalses.includes(false)) {
            document.getElementById("selectAll").checked = false;
          } else {
            if (checked === true) {
              document.getElementById("selectAll").checked = true;
            } else {
              document.getElementById("selectAll").checked = false;
            }
          }
        });
      } else {
        let tempSubscribers = this.state.data.map((subscriber, index2) => {
          if (index2 === index) {
            var x = subscriber;
            x["isChecked"] = checked;
            return x;
          } else {
            var x = subscriber;
            if (subscriber.isChecked != null) {
              x["isChecked"] = subscriber.isChecked;
            } else {
              x["isChecked"] = false;
            }
            return x;
          }
        });
        this.setState({ newData: tempSubscribers });

        let truesAndFalses = [];
        console.log("this.state.newData", this.state.newData);
        this.state.data.map((subscriber, index) => {
          truesAndFalses.push(subscriber.isChecked);
          if (truesAndFalses.includes(true) && truesAndFalses.includes(false)) {
            document.getElementById("selectAll").checked = false;
          } else {
            if (checked === true) {
              document.getElementById("selectAll").checked = true;
            } else {
              document.getElementById("selectAll").checked = false;
            }
          }
        });
      }
    }
  };
  acceptMany = () => {
  this.setState({
      loadintable: true,
    });
    axios
      .get(`${baseUrlTest}api/ResetIPRequests/Reset`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success("تم اعادة فتح الحسابات");
          this.setState({ loadintable: false });
        this.getAllResetRequests()
        }
        
      })
      .catch((error) => {
        toast.error("يوجد مشكلة ما");
        this.setState({ loadintable: false });
      });
       this.setState({
            loadintable: true,
            });
  };
  render() {
    const { loadintable, count } = this.state;
    const datatable = {
      columns: [
        // { label: "", field: "checkBox" },
        { label: "الترتيب", field: "index" },
        // { label: " اسم المادة", field: "lectureName" },
        { label: "اسم الطالب", field: "studentName" },
        // { label: "السبب", field: "reason" },
        { label: "الاختيارات", field: "option" },
      ],
      rows: this.state.data,
    };
    return (
      <div className="main-content">
        <div className="dashboard-header">
          <h5>اعاده فتح الحسابات</h5>
        </div>
        <div className="dashboard-content">
          <div className="dasboard-box">
            <div className="select-all-accounts">
              <div className="checkbox-for-select">
                {/* <input
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  onChange={(e) => this.onRowCheckChanged(e, e)}
                  style={{
                    marginLeft: 5 + "px",
                  }}
                />
                <span>تحديد الكل</span> */}
              </div>
              <div className="option-parent">
                <button
                  onClick={() => this.acceptMany()}
                  className="reset-all-account op-done"
                >
                  {/* <i className="fi-rr-check"></i> */}
                  اعاده تعين الكل
                </button>

                {/* <button
                  onClick={() => this.deleteMany()}
                  color="red"
                  className="tableOption op-delete"
                  size="sm"
                >
                  <i className="fi-rr-trash"></i>
                </button> */}
              </div>
            </div>
            <MDBDataTable
              entriesOptions={[5, 10, 15, 20]}
              entries={10}
              pagesAmount={4}
              hover
              large
              responsive
              data={datatable}
            />
            {loadintable ? (
              <div className="loading-par">
                <div className="sp sp-volume"></div>
                <h5>برجاء الانتظار</h5>
              </div>
            ) : null}
            {/* <Stack spacing={2}>
              <Pagination
                style={{ direction: "ltr" }}
                count={count}
                shape="rounded"
                onChange={this.handlePaginationChange}
              />
            </Stack> */}
          </div>
        </div>
      </div>
    );
  }
}
